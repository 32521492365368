<template>
    <div class="login">
        <el-dialog :visible.sync="visible" title="登录" :close-on-click-modal="false" :close-on-press-escape="false" width="35%">
            <el-form ref="form" :model="form" class="form">
                <el-form-item>
                    <el-input v-model="form.name" placeholder="请输入您的联系电话"></el-input>
                </el-form-item>
                <el-form-item class="form_item">
                    <div class="getCodeBtn">获取验证码</div>
                    <el-input v-model="form.name" placeholder="请输入验证码"></el-input>
                </el-form-item>
                <div class="submit">登录</div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            form: {}
        }
    },
    methods: {
        init() {
            this.visible = true
            this.$nextTick(() => {

            })
        }
    }
}
</script>
<style lang="scss">
.login {
    input {
        height: 48px;
    }
}
</style>

<style lang="scss" scoped>
.form{
    padding: 0 109px;
    .submit{
        width: 100%;
        height: 48px;
        background: #FE7002;
        color: #fff;
        line-height: 48px;
        border-radius: 10px;
    }
}
.form_item {
    position: relative;

    .getCodeBtn {
        position: absolute;
        width: 130px;
        height: 48px;
        line-height: 48px;
        background: #0051D6;
        color: #fff;
        font-size: 14px;
        z-index: 100;
        right: 0;
        border-radius: 10px;
    }
}
</style>