<template>
  <div id="app">
    <headers v-if="$route.path.indexOf('/m') < 0"></headers>
    <!-- <router-view/> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
      </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
    </router-view>
    <footers v-if="$route.path.indexOf('/m') < 0"></footers>
  </div>
</template>

<script>
// @ is an alias to /src

import headers from "@/components/headers.vue"
import footers from "@/components/footers.vue"
export default {
  name: 'HomeView',
  components: {
    headers,footers
  },
  created() {
    if (this.isMobileDevice()) {
        window.location.href = "http://mobile.chenglan114.com/"; // 替换为你的移动版网址
    }
  },
  methods: {
    isMobileDevice() {
      return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.includes("Mobile") ||
        navigator.userAgent.includes("Android") ||
        navigator.userAgent.includes("iPhone") ||
        navigator.userAgent.includes("iPad") ||
        navigator.userAgent.includes("iPod") ||
        navigator.userAgent.includes("BlackBerry") ||
        navigator.userAgent.includes("IEMobile") ||
        navigator.userAgent.includes("Opera Mini")
      );
    }
  },
}
</script>



<style lang="scss">
/* 隐藏水平滚动条 */
::-webkit-scrollbar {
  // width: 0px; /* Chrome、Safari等WebKit内核浏览器支持此属性 */
}
 
/* Firefox浏览器需要添加以下样式才能完全隐藏滚动条 */
body {
  // scrollbar-width: none; /* Firefox浏览器支持此属性 */
  margin: 0;
  // overflow-x: hidden; /* 或者使用 overflow: hidden */
  // min-width: 1400px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.all_box{
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
</style>
