<template>
  <div>
    <div class="home">
      <!-- banner  -->
      <!-- <div class="banner"></div> -->
      <img src="@/assets/home/banner.png" class="banner">
    </div>
    <div class="allEdit">
        
      <div class="editItem">
        <div class="left">
          <div class="title">哪些情况下需要租用商标？</div>
          <div class="word">Under what circumstances is it necessary to rent a trademark?</div>
        </div>
        <div class="right">
          <div class="editItemImg"></div>
          <div class="item">
            <img src="../assets/home/bac.png" class="itemImg">
            <div class="content">想好的名字被注册</div>
          </div>
          <div class="item">
            <img src="../assets/home/bac.png" class="itemImg">
            <div class="content">好产品急需好名字</div>
          </div>
          <div class="item">
            <img src="../assets/home/bac.png" class="itemImg">
            <div class="content3">入驻电商平台缺少商标</div>
          </div>
          <div class="item">
            <img src="../assets/home/bac.png" class="itemImg">
            <div class="content4">项目启动阶段</div>
            <div class="content5">不想花太多成本</div>
          </div>
        </div>
      </div>
    </div>
    <div class="home"> 
      <!-- <div class="all">
        <div class="left">
          <div class="title">哪些情况下需要<span class="titleBold">租用商标？</span></div>
          <div class="flex">
            <img src="../assets/home/one.png" class="number">
            <span class="word">想好的名字被注册</span>
          </div>
          <div class="flex">
            <img src="../assets/home/two.png" class="number">
            <span class="word">好产品急需好名字</span>
          </div>
          <div class="flex">
            <img src="../assets/home/three.png" class="number">
            <span class="word">入驻电商平台缺少商标</span>
          </div>
          <div class="flex">
            <img src="../assets/home/four.png" class="number">
            <span class="word">项目启动阶段不想花太多成本</span>
          </div>
        </div>
        <img src="../assets/home/bannerRight.png" class="bannerRight">
      </div> -->

      <div class="all2Edit">
        <div class="reason">
          <div class="title">选择我们的理由</div>
          <div class="bottom">
            <div class="item">
              <img src="../assets/home/one.png" class="itemImg">
              <span class="word">一手商标，无中介费</span>
              <img src="../assets/home/symbol.png" class="numberIcon">
            </div>
            <div class="item">
              <img src="../assets/home/two.png" class="itemImg">
              <span class="word">成本更低，前期免费</span>
              <img src="../assets/home/symbol.png" class="numberIcon">
            </div>
            <div class="item">
              <img src="../assets/home/three.png" class="itemImg">
              <span class="word">资质齐全，安全可靠</span>
              <img src="../assets/home/symbol.png" class="numberIcon">
            </div>
            <div class="item">
              <img src="../assets/home/four.png" class="itemImg">
              <span class="word">省心省力，无驳回风险</span>
              <img src="../assets/home/symbol.png" class="numberIcon">
            </div>
            <div class="item">
              <img src="../assets/home/five.png" class="itemImg">
              <span class="word">省时省力，即租即用</span>
              <img src="../assets/home/symbol.png" class="numberIcon">
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="all2">
        <img src="../assets/home/reasonImg.png" class="reasonImg">
        <div class="right">
          <div class="title">选择我们的<span class="titleBold">理由</span></div>
          <div class="navFlex">
            <div class="flexS">
              <img src="../assets/home/symbol.png" class="numberS">
              <span class="wordS">资质齐全，安全可靠</span>
            </div>
            <div class="flexS">
              <img src="../assets/home/symbol.png" class="numberS">
              <span class="wordS">省心省力，无驳回风险</span>
            </div>
            <div class="flexS">
              <img src="../assets/home/symbol.png" class="numberS">
              <span class="wordS">省时省力，即租即用</span>
            </div>
            <div class="flexS">
              <img src="../assets/home/symbol.png" class="numberS">
              <span class="wordS">一手商标，无中介费</span>
            </div>
            <div class="flexS">
              <img src="../assets/home/symbol.png" class="numberS">
              <span class="wordS">成本更低，前期免费</span>
            </div>
          </div>
        </div>
      </div> -->

      <div class="all3">
        <div class="all3Item">
          <p>商标租用流程</p>
          <div class="flex1">
            <img src="../assets/home/process1.png" class="number">
            <span class="word">1.选择心仪的商标</span>
          </div>
          <img src="../assets/home/processCenter.png" class="processCenter1">
          <div class="flex2">
            <img src="../assets/home/process2.png" class="number">
            <span class="word">2.确定租赁</span>
          </div>
          <img src="../assets/home/processCenter.png" class="processCenter2">
          <div class="flex3">
            <img src="../assets/home/process3.png" class="number">
            <span class="word">3.签订合同</span>
          </div>
          <img src="../assets/home/processCenter.png" class="processCenter3">
          <div class="flex4">
            <img src="../assets/home/process4.png" class="number">
            <span class="word">4.办理授权</span>
          </div>
          <img src="../assets/home/processCenter.png" class="processCenter4">
          <div class="flex5">
            <img src="../assets/home/process5.png" class="number">
            <span class="word">5.开始使用</span>
          </div>
        </div>
      </div>
      

    </div>
  </div>
</template>

<script>
import allTitles from "@/components/allTitles.vue"
export default {
  name: 'HomeView',
  components: {
    allTitles
  },
  mounted() {
    
  },
  methods: {
    
  },
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family: 'Microsoft YaHei';
  src: url('../assets/msyh.ttf');
}
.home {
  width: 100%;
  margin: 0 auto;
}
.banner {
  background: url(../assets/home/banner.png) center center no-repeat;
  background-size: 100%;
  max-width: 1920px;
  width: 100%;
  height: 640px;
}
.allEdit {
  height: 300px;
  margin-bottom: 143px;
  display: flex;
  align-items: center;
  max-width: 1920px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  .editItemImg {
    max-width: 1188px;
    width: 100vw;
    height: 150px;
    background: linear-gradient(90deg, #FE6249 0%, #FED664 100%);
    position: absolute;
    left: -10px;
  }
  .editItem {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    .left {
      .title {
        font-family: Microsoft YaHei;
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0em;
        color: #333333;
        max-width: 384px;
        height: 42px;
        margin-bottom: 14px;
      }
      .word {
        font-family: Microsoft YaHei;
        font-size: 16px;
        font-weight: 290;
        line-height: 21px;
        letter-spacing: 0em;
        color: #666666;
        max-width: 383px;
        height: 42px;
        text-align: left;
      }
    }
    
    .right {
      // width: 1188px;
      // height: 150px;
      // background: linear-gradient(90deg, #FE6249 0%, #FED664 100%);
      position: absolute;
      right: 0;
      display: flex;
      // padding-left: 12px;
      .item {
        width: 216px;
        height: 140px;
        background-color: #ffffff;
        margin-top: 49px;
        margin-left: 16px;
        box-shadow: 0px 6px 20px 0px #0000001A;
        position: relative;
        .itemImg {
          width: 216px;
          height: 140px;
        }
        .content {
          position: absolute;
          top: 26px;
          left: 53px;
          width: 110px;
          height: 76px;
          font-family: Microsoft YaHei;
          font-size: 22px;
          font-weight: 700;
          line-height: 38px;
          letter-spacing: 0em;
          text-align: center;
          color: #FE6249;
        }
        .content3 {
          position: absolute;
          top: 26px;
          left: 42px;
          width: 132px;
          height: 76px;
          font-family: Microsoft YaHei;
          font-size: 22px;
          font-weight: 700;
          line-height: 38px;
          letter-spacing: 0em;
          text-align: center;
          color: #FE6249;
        }
        .content4 {
          position: absolute;
          top: 26px;
          left: 31px;
          width: 154px;
          height: 76px;
          font-family: Microsoft YaHei;
          font-size: 22px;
          font-weight: 700;
          line-height: 38px;
          letter-spacing: 0em;
          text-align: center;
          color: #FE6249;
        }
        .content5 {
          position: absolute;
          top: 64px;
          left: 31px;
          width: 154px;
          height: 76px;
          font-family: Microsoft YaHei;
          font-size: 22px;
          font-weight: 700;
          line-height: 38px;
          letter-spacing: 0em;
          text-align: center;
          color: #FE6249;
        }
      }
    }
  }
}
.all {
  width: 1920px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 141px;
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title {
      font-family: Microsoft YaHei;
      font-size: 40px;
      font-weight: 700;
      line-height: 53px;
      text-align: center;
      color:#4D4D4D;
      margin-top: 207px;
      margin-bottom: 75px;
      .titleBold {
        color: #FE7002;
      }
    }
    .flex {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .number {
        width: 52px;
        height: 51px;
        border-radius: 8px;
      }
      .word {
        font-family: Microsoft YaHei;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        color: #333333;
        margin-left: 32px;
      }
    }
  }
  .bannerRight {
    width: 1025px;
    height: 682px;
  }
}
.all2 {
  width: 1920px;
  height: 400px;
  background: #F9FBFF;
  position: relative;
  margin-bottom: 100px;
  .reasonImg {
    position: absolute;
    left: 260px;
    top: -41px;
    width: 554px;
    height: 400px;
    border-radius: 68px;
  }
  .right {
    position: absolute;
    left: 917px;
    top: 44px;
    .title {
      font-family: Microsoft YaHei;
      font-size: 32px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: left;
      color: #4D4D4D;
      margin-bottom: 54px;
      .titleBold {
        color: #FE7002;
      }
    }
    .navFlex {
      flex-wrap: wrap-reverse;
      display: flex;
      width: 900px;
      .flexS {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-right: 102px;
        margin-bottom: 50px;
        .numberS {
          width: 19px;
          height: 19px;
          margin-bottom: 7px;
        }
        .wordS {
          font-family: Microsoft YaHei;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          color: #333333;
        }
      }
    }
  }
}
.all2Edit {
  // max-width: 1920px;
  width: 100%;
  height: 740px;
  background: linear-gradient(257.06deg, #FAF9FF 0%, rgba(251, 250, 255, 0) 100.85%);
  margin-bottom: 58px;
  margin-top: 100px;
  .reason {
    width: 1470px;
    height: 600px;
    margin: 0 auto;
    // background-color: #ffffff;
    .title {
      font-family: Microsoft YaHei;
      font-size: 32px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: center;
      color: #333333;
      padding-top: 73px;
      margin-bottom: 96px;
    }
    .bottom {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 1470px;
      background: linear-gradient(257.06deg, #FAF9FF 0%, rgba(251, 250, 255, 0) 100.85%);
      .item {
        height: 171px;
        border-radius: 16px;
        margin-left: 35px;
        margin-right: 35px;
        background-color: #FE6249;
        display: flex;
        align-items: center;
        margin-bottom: 75px;
        box-shadow: -1px 16px 40px 0px #232F4214;
        background-color: #ffffff;
        position: relative;
        .itemImg {
          width: 88px;
          height: 88px;
          border-radius: 8px;
          margin-left: 39px;
        }
        .word {
          margin-left: 39px;
          font-family: Microsoft YaHei;
          font-size: 22px;
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0em;
          text-align: center;
          color: #333333;
          margin-right: 56px;
        }
        .numberIcon {
          width: 28px;
          height: 23px;
          position: absolute;
          top: -12px;
          right: 37px;
        }
      }
    }
  }
}
.all3 {
  background: url(../assets/home/processBac.png) center center no-repeat;
  background-size: 1920px;
  max-width: 1920px;
  width: 100%;
  height: 360px;
  position: relative;
  margin: 0 auto;
  .all3Item {
    width: 100%;
    max-width: 1400px;
    height: 360px;
    margin: 0 auto;
    position: relative;
  }
  p {
    font-family: Microsoft YaHei;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    padding-top: 90px;
    padding-left: 60px;
  }
  .flex1 {
    position: absolute;
    top: 128px;
    left: 357px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .number {
      width: 64px;
      height: 64px;
      margin-bottom: 45px;
    }
    .word {
      color: #ffffff;
    }
  }
  .processCenter1 {
    width: 32px;
    height: 10px;
    position: absolute;
    top: 152px;
    left: 563px;
  }
  .flex2 {
    position: absolute;
    top: 126px;
    left: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .number {
      width: 64px;
      height: 64px;
      margin-bottom: 45px;
    }
    .word {
      color: #ffffff;
    }
  }
  .processCenter2 {
    width: 32px;
    height: 10px;
    position: absolute;
    top: 152px;
    left: 806px;
  }
  .flex3 {
    position: absolute;
    top: 125px;
    left: 883px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .number {
      width: 64px;
      height: 64px;
      margin-bottom: 45px;
    }
    .word {
      color: #ffffff;
    }
  }
  .processCenter3 {
    width: 32px;
    height: 10px;
    position: absolute;
    top: 152px;
    left: 1039px;
  }
  .flex4 {
    position: absolute;
    top: 128px;
    left: 1116px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .number {
      width: 64px;
      height: 64px;
      margin-bottom: 45px;
    }
    .word {
      color: #ffffff;
    }
  }
  .processCenter4 {
    width: 32px;
    height: 10px;
    position: absolute;
    top: 152px;
    left: 1232px;
  }
  .flex5 {
    position: absolute;
    top: 128px;
    left: 1309px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .number {
      width: 64px;
      height: 64px;
      margin-bottom: 45px;
    }
    .word {
      color: #ffffff;
    }
  }
}
</style>