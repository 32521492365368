<template>
    <div class="header">
        <div class="all_box">
            <div class="headers_box">
                <div class="header_logo">
                    <img src="../assets/logo.png" />
                </div>
                <div class="header_right">
                    <div class="header_nav">
                        <div class="header_nav_item" :class="$route.name == 'home'?'gl_on':'gl_off'" @click="tabClick('home')">首页</div>
                        <div class="header_nav_item" :class="$route.name == 'business'||$route.name == 'businessDetail'?'gl_on':'gl_off'" @click="tabClick('business')">商标租赁</div>
                        <div class="header_nav_item" :class="$route.name == 'logoDesign'?'gl_on':'gl_off'" @click="tabClick('logoDesign')">商标设计</div>
                        <div class="header_nav_item" :class="$route.name == 'about'?'gl_on':'gl_off'" @click="tabClick('about')">关于橙兰</div>
                        <div class="header_nav_item" :class="$route.name == 'contact'?'gl_on':'gl_off'" @click="tabClick('contact')">联系我们</div>
                    </div>
                    <div class="head_login" @click="openLogin">登录</div>
                    <div class="header_phone" style="display: none;">15257689574</div>
                </div>
            </div>
        </div>

        <login ref="login" v-if="loginVisible"></login>
    </div>
</template>
<script>
import login from '@/components/login.vue';
export default {
    components:{
        login
    },
    data(){
        return{
            loginVisible:false
        }
    },
    methods: {
        tabClick(name) {
            this.$router.push({
                name: name,
            });
        },
        openLogin(){
            this.loginVisible = true
            this.$nextTick(() => {
                this.$refs.login.init()
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.gl_on{
    color: #FE7002 !important;
    position: relative;
}
.gl_on:after{
    content:'';
    position: absolute;
    left: 0;
    height: 1px;
    width: 56px;
    background: #FE7002;
    bottom: 1px;
}
.headers_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.header_logo {
    img {
        width: 152px;
    }
}

.header_right {
    line-height: 55px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .header_nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 600px;

        .header_nav_item {
            width: 56px;
            font-size: 14px;
            color: #333333;
            cursor: pointer;
        }
    }

    .header_phone {
        padding: 0 0 0 50px;
        font-size: 16px;
        color: #666;
    }

    .head_login{
        margin:0 0 0 50px;
        width: 70px;
        height: 34px;
        line-height: 34px;
        background: #FE7002;
        color: #fff;
        font-size: 14px;
        margin-top: 10px;
        border-radius: 6px;
        cursor: pointer;
    }
}
</style>