<template>
    <div class="foot">
        <div class="all_box">
            <div class="foot_top">
                <div class="foot_left">
                    <div class="foot_left_item" @click="tabClick('home')">网站首页</div>
                    <div class="foot_left_item" @click="tabClick('business')">商标租赁</div>
                    <div class="foot_left_item" @click="tabClick('logoDesign')">商标设计</div>
                    <!-- <div class="foot_left_item">业务范围</div>
                    <div class="foot_left_item">成功案例</div>
                    <div class="foot_left_item">租售发布</div> -->
                    <div class="foot_left_item" @click="tabClick('about')">关于橙兰</div>
                    <div class="foot_left_item" @click="tabClick('contact')">联系我们</div>
                </div>
                <div class="foot_right">
                    <h3>400-891-5050</h3>
                    <p @click="tabClick('contact')">Contact us</p>
                </div>
            </div>
            <div class="foot_bottom">
                橙兰传媒 版权所有2020-{{ currentYear }} 浙ICP备2021029626号
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components:{},
    data(){
        return{
            currentYear: new Date().getFullYear()
        }
    },
    methods: {
        tabClick(name) {
            this.$router.push({
                name: name,
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.foot {
    background: #272727;
    padding-top: 100px;
    width: 100%;
    .foot_top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 68px;
        border-bottom: 1px solid rgba(223,223,230,.2);

        .foot_left {
            width: 40%;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;

            .foot_left_item {
                width: 33%;
                font-size: 16px;
                color: #fff;
                cursor: pointer;
            }
        }

        .foot_right{
            color: #fff;
            text-align: right;
            h3{
                font-size: 30px;
                margin: 0;
            }
            p{
                font-size: 16px;
                margin-top: 9px;
                cursor: pointer;
            }
        }
    }
}
.foot_bottom{
    color: #fff;
    font-size: 12px;
    padding: 6px 0;
}
</style>