<template>
    <div class="title">
        <h3>{{h3}}</h3>
        <p>{{p}}</p>
    </div>
</template>
<script>
export default {
    props: {
        h3:{
            type:String
        },
        p:{
            type:String
        }
    }
}
</script>
<style lang="scss" scoped>
.title{
    text-align: left;
    h3{
        color: #333;
        font-size: 24px;
        margin: 0;
    }
    p{
        color: #666;
        font-size: 18px;
        margin: 0;
        margin-top: 4px;
        line-height: 36px;
    }
}
</style>