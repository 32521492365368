import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }, {
    path: '/logoDesign',
    name: 'logoDesign',
    component: () => import('../views/LogoDesignView.vue')
  }, {
    path: '/ymglDetail',
    name: 'ymglDetail',
    component: () => import('../views/ymglDetail.vue')
  }, {
    path: '/business',
    name: 'business',
    meta: { keepAlive: true },
    component: () => import('../views/BusinessView.vue')
  }, {
    path: '/businessDetail',
    name: 'businessDetail',
    component: () => import('../views/BusinessSbjyDetail.vue')
  },{
    path: '/case',
    name: 'case',
    component: () => import('../views/CaseView.vue')
  },{
    path:'/contact',
    name:'contact',
    component:() => import('../views/ContactView.vue')
  },{
    path:'/lease',
    name:'lease',
    component:() => import('../views/LeaseView.vue')
  },{
    path:'/caseDetail',
    name:'caseDetail',
    component:() => import('../views/CaseDetailView.vue')
  },

  {
    path:'/m',
    name:'moblie',
    component:() => import('../views/mobile/home.vue')
  },
  {
    path:'/m/business',
    name:'moblieBusiness',
    component:() => import('../views/mobile/business.vue')
  },
  {
    path:'/m/businessDetail',
    name:'moblieBusinessDetail',
    component:() => import('../views/mobile/businessDetail.vue')
  },
  {
    path:'/m/logoDesign',
    name:'moblieLogoDesign',
    component:() => import('../views/mobile/LogoDesignView.vue')
  }
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    }
    return {
        x: 0,
        y: 0
    }
  },
  routes
})

export default router
